@import "../App";

#popup-ctr {
  position: absolute;
  display: flex;
  background: rgba(0,0,0,.5);
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  z-index: 9999;
}

#popup {
  border-radius: $default_radius;
  background: ghostwhite;
  margin: auto;
  padding: $default_margin;
}

#game {
  width: 100%;
  min-height: 400px;
  height: 100%;
}

#game-switch {
  display: flex;
  text-align: center;
  align-items: center;
  p {
    margin-bottom: 0;
  }
}

#btn-left {
  margin: $default_margin $default-margin $default-margin auto;
}

#btn-right {
  margin: $default-margin auto $default-margin $default-margin;
}

.game-btn {
  background: none;
  border: none;
}