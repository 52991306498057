@import "../App.scss";

.grade-bar {
  width: 75%;
  min-height: 25px;
  background-image: linear-gradient(90deg, rgba(255,0,0,0.6) 0%, rgba(255, 153, 51, 0.6) 25%,
          rgba(204, 204,0, 0.6) 50%, rgba(0,153,51, 0.6) 75%, rgba(0,102,0, 0.8) 100%); //emulate the 5 AP scores in colors
  margin: auto;
  display: flex;
  justify-content: space-between;
  border-right: 2px black solid; //emulate the last divider because last divider is the actual score separator
}

.divider {
  height: 25px;
  width: 2px;
  background: black;
}

.grade-divider {
  position: relative;
  width: 4px;
  border-radius: $default_radius;
  background: blue;
}

.grade-score {
  margin: auto;
}

.grade-comments {
  width: 85%;
  margin: $default-margin auto;
}

.grade-info {
  font-size: 17px;
}