@import "../App.scss";

#loading-container {
  background: silver;
  min-width: 40%;
  border-radius: $default-radius;
  text-align: center;
  padding: $default-margin;
}

#loading-text {
  margin: $default-margin;
  font-size: 50px;
}

#corny-text {
  margin: $default-margin;
}