@import "../App";

#sidebar {
  box-shadow: $box_shadow_size $og_blue;
  z-index: 999;
  background: $og_blue;
  flex: 0 1 auto;
  min-width: 250px;
  min-height: 90px;
}

.sidebar-el {
  min-width: 120px;
  margin: 25px 20px 0 20px;
  display: flex;
  flex-flow: column;
  font-family: Serifa, serif;
  button {
    background: transparent;
    border: none;
    color: white;
    font-size: 20px;
    text-shadow: 0 0 4px black;
  }
}

#sb-list {
  max-height: 100%;
  overflow: auto;
  flex: 0 1 auto;
  display: flex;
}

.side-arrow {
  font-size: 20px;
  margin-right: calc($default-margin/2);
  text-shadow: none;
}

.rotated {
  rotate: 90deg;
}