@import "../App";

$btn-font-size: 25px;

.tan-btn:hover {
  background: #f0d5c1 !important;
}

.tan-btn:active {
  background: #ebc7ad !important;
}

.file-btn {
  background: none;
  border: solid 2px black;
  border-radius: $default-radius;
  margin: 0 $default-margin;
  @media (max-width:850px) {
    font-size: 20px;
  }
  font-size: $btn-font-size;
  padding: 0 10px;
  box-shadow: #5c3724 2px 2px;
}

#pdf {
  margin-right: $default-margin/2; //for some reason, camera has some extra right margin. No need to add margin for camera
}

.file-i {
  color: $og_blue;
}

#btn-container {
  p {
    font-size: 20px;
  }
}

#manual-text {
  margin: $default-margin*2 0;
  font-size: 18px;
}

.frq-input {
  border-radius: $default-radius;
  min-height: 10vh;
  margin: $default_margin;
  background: $light_sandy;
  border: black solid 1px;
}

.frq-letter {
  margin: 0 $default-margin;
}

#submit-btn {
  margin: 0 auto;
  background: none;
  color: black;
  border: solid 2px black;
  border-radius: $default-radius;
  font-size: $btn-font-size;
  width: 75%;
  box-shadow: #5c3724 2px 2px;
}

#img-text {
  font-size: 15px;
}

#hidden-file {
  width: 0;
  height: 0;
}

#submit-cntr {
  margin: $default_margin*2 0;
}

.subq {
  font-size: 15px;
}