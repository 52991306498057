$default-radius: 10px;
$default-margin: 10px;
$og_blue: #324dc7;
$dark_sandy: #f5e3d6;
$light_sandy: #faf1eb;
$box_shadow_size: 0 0 10px 4px;

@font-face {
  font-family: 'Serifa';
  src: url("./fonts/Serifa Std Bold.ttf");
}