@import '../App';
@import 'inputs';

#screen {
  background: url("../img/pexels-rafael-paul-4797130.jpg");
  background-size: cover;

  > div {
    padding: 4 * $default-margin;
    background: $dark_sandy;
    background-size: cover;
    min-height: 100%;
    box-shadow: $box_shadow_size $dark_sandy;
  }

  h1,h2,h3,h4,h5 {
    font-family: Serifa, serif;
  }
  :not(h1,h2,h3,h4,h5) {
    font-family: "Roboto Slab", sans-serif;
  }
}

#view-rbc-btn {
  background: none;
  border: none;
  color: blue;
  font-size: $btn-font-size;
}