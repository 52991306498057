@import "../App";
@import "bootstrap/scss/bootstrap";

$med_rubric_bg: #8897dd;
$light_rubric_bg: #9ca9e3;

$rubric_side_border: black solid 1px;

.rubric-table {
  background: $med_rubric_bg;
  border-radius: $default_radius $default-radius 0 0;
  td {
    padding: $default-margin;
    border-collapse: collapse;
    min-width: 200px;
  }
}

.table-scroll {
  flex-grow: 1;
  flex-shrink: 0;
  display: block;
  overflow-x: auto;
  margin: $default_margin 0;
}

.rbc-br-left {
  border-left: $rubric_side_border;
}

.rbc-br-right {
  border-right: $rubric_side_border;
}

.rubric-header {
  font-size: 50px;
  text-align: center;
  border-radius: $default_radius $default_radius 0 0;
}

.question-bg {
  background: $light_rubric_bg;
}

.guidelines-bg {
  background: $med_rubric_bg;
}

.table-top-bg {
  background: silver;
}

th {
  font-family: Serifa, serif !important;
}

th:not(.rubric-header) {
  text-decoration: underline;
}

.scorecard {
  text-align: center;
  font-family: Serifa, serif !important;
  font-size: 40px;
  margin-bottom: $default_margin;
}

.comments {
  margin: $default_margin;
  font-size: 30px;
}

.gpt-comments {
  color: #198754;
}

.graph-overlay {
  font-size: 30px;
  font-weight: bold;
  text-align: center;
  background: rgba(125,125,125,1);
}

.met {
  background: $success !important;
}

.failed {
  background: $danger !important;
}